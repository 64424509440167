import React from "react";
import { Router } from "@reach/router";

import SubSystemListingPage from "../components/PageComponents/SubSystemListingPage";
import SubSystemAddPage from "../components/PageComponents/SubSystemAddPage";
import SubSystemUpdatePage from "../components/PageComponents/SubSystemUpdatePage";

const subsystems = (props) => (
  <Router>
    <SubSystemListingPage {...props} path="/subsystems" />
    <SubSystemAddPage {...props} path="/subsystems/add-subsystem" />
    <SubSystemUpdatePage {...props} path="/subsystems/:subSystemId" />
  </Router>
);

export default subsystems;
