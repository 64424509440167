import React, { useEffect, useState } from "react";
import { useToast, useTheme } from "@chakra-ui/react";
import { Switch } from "@chakra-ui/switch";
import { get, omit } from "lodash";
import { useLocation } from "@reach/router";
import { stringify } from "query-string";
import { navigate } from "gatsby";
import moment from "moment";
import { Field, Form as FinalForm } from "react-final-form";

import { get as GET, put } from "../../../utilities/http";
import toastMessage from "../../../utilities/toastMessage";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Box from "../../base/Box";
import Heading from "../../base/Heading";
import Button from "../../base/Button";
import Spinner from "../../base/Spinner";
import Table from "../../Table";
import Icon from "../../Icon";
import Dropdown from "../../base/Dropdown";
import {
  ALL_FLEET,
  STATUS,
  TOTAL_JUMP_PAGES,
} from "../../../utilities/constants";
import search from "../../../utilities/search";
import DashboardPaginator from "../../CommonComponents/DashboardPaginator";
import dashboardConstants from "../../../utilities/dashboard-constants";
import responsiveCss from "../../../utilities/responsiveCss";
import generateSearchString from "../../../utilities/generate-search-string";
import removeTrailingSlashes from "../../../utilities/removeTrailingSlashes";

const SubSystemListingPage = () => {
  const theme = useTheme();
  const toast = useToast();
  const [subsystemData, setSubSystemData] = useState({});
  const [sample, setSample] = useState(true);
  const [systemDropDownOptions, setSystemDropDownOptions] = useState([]);
  const [cityDropDownOptions, setCityDropDownOptions] = useState([]);
  const [filter, setFilter] = useState({});
  const [searchValue, setSearchValue] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const sampleToggle = () => {
    setSample(!sample);
  };
  const { filters, pageCriteria } = dashboardConstants.getCriteria({
    search: location.search,
    type: "subsystems",
  });
  const totalJumpToPages = responsiveCss(
    TOTAL_JUMP_PAGES.MOBILE,
    TOTAL_JUMP_PAGES.DESKTOP
  );

  useEffect(async () => {
    try {
      const { data } = await GET("/listing-cities");
      const cityDropDownData = data.map((city) => ({
        label: `#${get(city, "city_id")} - ${get(city, "city")}`,
        value: get(city, "city_id"),
      }));
      setCityDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...cityDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, []);
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
      });
      const data = await GET(`/listing-system?${generateSearchString(query)}`);
      const systemDropDownData = get(data, "data", []).map((system) => ({
        label: `#${get(system, "system_id")} - ${get(system, "system")}`,
        value: get(system, "system_id"),
      }));
      setSystemDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...systemDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [location]);
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
        page: pageCriteria,
      });
      const data = await GET(
        `/listing-subsystem?${generateSearchString(query)}`
      );
      if (data) {
        const filteredSubsystemData = searchValue
          ? search(get(data, "data", []), searchValue, "subsystem")
          : get(data, "data", []);

        setSubSystemData({ ...data, data: filteredSubsystemData });
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [sample, location]);
  const tableHeader = [
    { label: "#", value: "systemId" },
    { label: "Sub-sistema", value: "subsystem" },
    { label: "Ciudad", value: "city" },
    { label: "Sistema", value: "system" },
    { label: "Habilitado", value: "enabled" },
    { label: "Creación", value: "creationDate" },
    { label: "Acciones", value: "actions" },
  ];
  const pageTitle = "Listado de sub-sistemas";
  // const loading = false;
  const gap = 4;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: ["100%", null, null, "50%"],
    mb: gap,
    pr: gap,
  };
  return (
    <Route layout="admin">
      <Section sx={{ mx: "auto" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            mb: 4,
          }}
        >
          <Heading {...theme.variant.heading}>{pageTitle}</Heading>
          <Button
            onClick={() => {
              navigate("/subsystems/add-subsystem");
            }}
          >
            Añadir nuevo sub-sistema
          </Button>
        </Box>
        <Box>
          <FinalForm
            onSubmit={async () => {}}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: ["column", null, "row"],
                    mb: 8,
                  }}
                >
                  <Box
                    as="input"
                    id="search"
                    name="search"
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      sampleToggle();
                    }}
                    placeholder="Search"
                    sx={{
                      ...theme.variant.input.primary,
                      mb: 4,
                      mr: 4,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    type="text"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    name="city_id"
                    onChange={(value) => {
                      const filterObject =
                        value === ALL_FLEET
                          ? omit(filter, ["city_id"], ["system_id"])
                          : omit({ ...filter, city_id: value }, ["system_id"]);
                      setFilter(filterObject);
                      const searchParams = stringify({
                        filters: stringify(filterObject),
                        page: stringify({
                          limit: 10,
                          offset: 0,
                        }),
                      });
                      navigate(
                        `${removeTrailingSlashes(location.pathname)}${
                          searchParams ? "?" : ""
                        }${searchParams}`,
                        {
                          replace: true,
                        }
                      );
                    }}
                    options={cityDropDownOptions}
                    placeholder="Select City"
                    sx={{ maxWidth: "100%" }}
                    value={get(filters, "values.city_id")}
                    wrapperSx={{ ...colStyled }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    name="systemDropdown"
                    onChange={(value) => {
                      const filterObject =
                        value === ALL_FLEET
                          ? omit(filter, ["system_id"])
                          : { ...filter, system_id: value };
                      setFilter(filterObject);
                      const searchParams = stringify({
                        filters: stringify(filterObject),
                        page: stringify({
                          limit: 10,
                          offset: 0,
                        }),
                      });
                      navigate(
                        `${removeTrailingSlashes(location.pathname)}${
                          searchParams ? "?" : ""
                        }${searchParams}`,
                        {
                          replace: true,
                        }
                      );
                    }}
                    options={systemDropDownOptions}
                    placeholder="Select System"
                    sx={{ maxWidth: "100%" }}
                    value={get(filters, "values.system_id")}
                    wrapperSx={{ ...colStyled }}
                  />
                </Box>
              </form>
            )}
          />
        </Box>
        <Box>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Table
                header={tableHeader}
                renderEmpty="Sin datos"
                rows={get(subsystemData, "data", []).map((subsystem) => [
                  get(subsystem, "subsystem_id", "-"),
                  get(subsystem, "subsystem", "-"),
                  get(subsystem, "city", "-"),
                  get(subsystem, "system", "-"),
                  <Switch
                    key={`status-${subsystem.subsystem_id}`}
                    colorScheme="green"
                    defaultChecked={get(subsystem, "enabled") === STATUS.ACTIVE}
                    onChange={async () => {
                      try {
                        const payLoad = {
                          enabled:
                            get(subsystem, "enabled") === STATUS.ACTIVE
                              ? STATUS.INACTIVE
                              : STATUS.ACTIVE,
                          subsystem: get(subsystem, "subsystem"),
                          subsystem_id: get(subsystem, "subsystem_id"),
                        };
                        const data = await put("/update-subsystem", payLoad);
                        sampleToggle();
                        if (data) {
                          toastMessage({
                            message: "Subsystem Edited!",
                            toast,
                            type: "success",
                          });
                        }
                      } catch (e) {
                        toastMessage({
                          message: e.message,
                          toast,
                          type: "error",
                        });
                      }
                    }}
                    variant="primary"
                  />,
                  get(subsystem, "created_at")
                    ? moment(get(subsystem, "created_at")).format("LLLL")
                    : "-",
                  <Box
                    as="button"
                    color="mediumGreen"
                    onClick={() => {
                      // deleteToggle();
                      navigate(
                        `/subsystems/${get(subsystem, "subsystem_id")}`,
                        {
                          state: { subsystem },
                        }
                      );
                    }}
                  >
                    <Icon svg="edit" sx={{ width: "18px" }} />
                  </Box>,
                ])}
                tableSx={{
                  borderCollapse: "collapse",
                  overflow: "visible",
                }}
              />
              {subsystemData.total > 0 && (
                <DashboardPaginator
                  buttonSx={{
                    color: "primary",
                  }}
                  enableJumpToPage
                  filters={filters}
                  pageCriteria={pageCriteria}
                  pageTotal={get(subsystemData, "data", []).length}
                  total={get(subsystemData, "total")}
                  totalJumpToPages={totalJumpToPages}
                />
              )}
            </>
          )}
        </Box>
      </Section>
    </Route>
  );
};
export default SubSystemListingPage;
